<template>
  <div class="brief-box">
    <div class="brief-center">
      <SubTitle :title="title" :enTitle="enTitle">
      </SubTitle>
      <div class="brief-content">
        <div class="brief-left">
          <div class="brief-list">
            <div class="brief-item" v-for="(item,idx) in descList" :key="idx">
              <div class="item-left">
                <span></span>
              </div>
              <div class="item-right">
                <p>{{item.name}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="brief-right">
          <img src="@/assets/images/about/huanjing.png">
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import SubTitle from "../subTitle/index.vue";
export default {
  components: {
    SubTitle,
  },
  data() {
    return {
      title: "公司简介",
      // enTitle: "Company Profile",
      enTitle: "",
      descList: [{
        name: "时升数据科技有限公司，致力于成为数据科技与传统产业深度融合的引领者，以大数据、AI等前沿技术为核心驱动力，重塑玩具、黄金行业及其他的传统行业"
      },{
        name: " 我们将商品与产品设计全面数据化，以此为基础构建数字商业的元宇宙，让数据与知识成为电商发展的不竭源泉，赋予其前所未有的生命力与竞争力。"
      },{
        name: "我们坚信，通过不懈的努力与探索，能够引领一场前所未有的产业革命，共创一个更加智慧、包容、繁荣的数字未来。"
      }]
    };
  },
};
</script>
<style lang="less" scoped>
.brief-box {
  width: 100%;
  margin-top: 60px;
  .brief-center {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    .brief-content {
      width: 100%;
      display: flex;
      margin-top: 24px;
      .brief-left {
        flex: 1;
        padding-right: 40px;
        .brief-list {
          display: flex;
          flex-direction: column;
          .brief-item {
            display: flex;
            .item-left {
              display: flex;
              align-items: flex-start;
              padding-right: 20px;
              padding-top: 4px;
              span {
                width: 16px;
                height: 16px;
                border-radius: 100%;
                background: #50bfff;
                display: inline-block;
              }
            }
            .item-right {

            }
          }
        }
        p {
          font-weight: 400;
          font-size: 16px;
          color: #626a83;
          line-height: 20px;
          text-align: left;
          margin-bottom: 20px;
        }
      }
      .brief-right {
        img {
          width: 600px;
          height: 334px;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>