<template>
    <div class="solution-box">
        <div class="solution-center">
            <div class="top-header">
                <SubTitle :title="title" :enTitle="enTitle"></SubTitle>
                <div class="btn cursor" @click="goAbout">去行业定制</div>
            </div>
            <div class="solution-content">
                <ToyBi></ToyBi>
                <ToyAbility></ToyAbility>
                
            </div>
        </div>
        
    </div>
</template>
<script>
import SubTitle from "../subTitle/index.vue";
import ToyBi from "@/components/toy/bi.vue";
import ToyAbility from "@/components/toy/ability.vue";
export default {
    components: {
        SubTitle,
        ToyBi,
        ToyAbility
    },
    data() {
        return {
            title: "行业解决方案",
            // enTitle: "solution",
            enTitle: ""
        }
    },
    methods: {
        goAbout() {
            this.$router.push({
                path: "about?anchorTarget=contact"
            })
        }
    }
}
</script>
<style lang="less" scoped>
.solution-box {
    width: 100%;
    background: #fff;
    .solution-center {
        width: 1200px;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        padding-bottom: 60px;
    }
    .top-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 50px 0;
        .btn {
            width: 198px;
            height: 56px;
            background: #3672FD;
            border-radius: 10px;
            font-weight: bold;
            font-size: 20px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .solution-content {
        display: flex;
        justify-content: space-between;
      
    }
}
</style>