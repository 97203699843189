<template>
    <div class="solution-box">
        <div class="solution-center">
            <div class="top-header">
                <SubTitle :title="title" :enTitle="enTitle"></SubTitle>
                <div class="btn cursor" @click="goAbout">其他行业前去定制</div>
            </div>
            <div class="solution-content">
                <div class="solution-detail">
                    <div class="desc">通过AI大模型让每个用户按照自己的描述来表达需求和偏好，并直接以产品效果图的形式提供给厂家进行加工定制，大幅提升定制产品的效率。本方案适合珠宝首饰、高定模玩、企业纪念品等行业，为企业提供与客户进行产品定制对话的便捷通道。</div>
                    <div class="detail">
                        <div class="detail-left">
                            <div>美奥金家族</div>
                            <div>大国工匠非遗文化呈现平台</div>
                        </div>
                        <div class="detail-right">
                            <!-- <img src="@/assets/images/icon_1.jpg"> -->
                            <img style="width:532px;height:296px;" src="@/assets/images/amjjz.png">
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import SubTitle from "../subTitle/index.vue";
    import ToyBi from "@/components/toy/bi.vue";
    import ToyAbility from "@/components/toy/ability.vue";
    export default {
        components: {
            SubTitle,
            ToyBi,
            ToyAbility
        },
        data() {
            return {
                title: "智能商城解决方案",
                // enTitle: "solution",
                enTitle: ""
            }
        },
        methods: {
            goAbout() {
                this.$router.push({
                    path: "about?anchorTarget=contact"
                })
            }
        }
    }
</script>
<style lang="less" scoped>
    .solution-box {
        width: 100%;
        background: #fff;

        .solution-center {
            width: 1200px;
            display: flex;
            margin: 0 auto;
            flex-direction: column;
            padding-bottom: 60px;
        }

        .top-header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 50px 0;

            .btn {
                width: 198px;
                height: 56px;
                background: #3672FD;
                border-radius: 10px;
                font-weight: bold;
                font-size: 20px;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .solution-content {
            display: flex;
            justify-content: space-between;

            .solution-detail {
                
                .desc {
                    font-weight: 400;
                    font-size: 14px;
                    color: #626A83;
                    line-height: 20px;
                    margin-top: 7px;
                    text-align: left;
                    padding: 0 30px;
                }
                .detail {
                    display: flex;
                    margin: 0 80px;
                    margin-top: 30px;
                    /* height: 160px; */
                    border: 1px solid #f2f2f2;
                    font-size: 20px;
                    color: #626A83;
                    line-height: 30px;
                    .detail-left {
                        width: 40%;
                        border-right: 1px solid #f2f2f2;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    }
                    .detail-right {
                        width: 60%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
</style>