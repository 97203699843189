<template>
  <div class="vision-box">
    <!-- <div class="popup-layer"></div> -->
    <div class="vision-center">
      <div class="vision-content">
        <div class="content-item content-item1">
          <div class="name">愿景</div>
          <div class="vision-list">
            <div class="vision-item">
              <div class="desc">致力于以用户需求为核心，构造一个生产者、消费者和创意者之间无缝连接、协同共创的产品生态系统</div>
            </div>
            <div class="vision-item">
              <div class="desc">我们将进一步推动人类智慧与能力的全面互联，打破地域、行业的界限，为每个人提供实现自我价值、参与创新创造的平台，助力实现全民就业与共同富裕的美好愿景。</div>
            </div>
          </div>

        </div>
        <div class="content-item">
          <div class="name">使命</div>
          <div class="desc">以数据和AI科技赋能传统产业，推动数字化转型</div>
        </div>
        <!-- <div class="vision-img">
            <img src="@/assets/images/about/yuanjing.png">
        </div> -->
        <!-- <div class="vision-popup">
            <div class="vision-list">
                <div class="vision-item" v-for="(item,idx) in visionList" :key="idx">
                    <div class="name">{{item.name}}</div>
                    <div class="en-name">{{item.enName}}</div>
                    <div class="desc">{{item.desc}}</div>
                </div>
            </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        visionList: [{
          name: "愿景",
          enName: "Vision",
          desc: "创造价值，改变世界"
        }, {
          name: "使命",
          enName: "mission",
          desc: "为用户提供最优质的服务"
        }, {
          name: "价值观",
          enName: "values",
          desc: "诚信、尊重、团队、专业"
        }]
      }
    },
    created() {
    }
  };
</script>
<style lang="less" scoped>
  .vision-box {
    width: 100%;
    /* margin-top: 220px; */
    margin-top: 30px;
    position: relative;

    .popup-layer {
      position: absolute;
      width: 50%;
      top: -132px;
      left: 0;
      height: 416px;
      z-index: 2;
      background: #3672FD;
    }

    .vision-center {
      width: 1200px;
      display: flex;
      margin: 0 auto;
      flex-direction: column;

      .vision-content {
        width: 100%;
        display: flex;
        position: relative;

        .content-item {
          width: 33.33%;

          &.content-item1 {
            width: 66.66%;
          }

          .name {
            font-weight: bold;
            font-size: 24px;
            color: #1A232F;
            line-height: 33px;
          }

          .desc {
            font-weight: 400;
            font-size: 16px;
            color: #626a83;
            line-height: 20px;
            margin-top: 40px;
            padding: 0 20px;
          }

          .vision-list {
            display: flex;

            .vision-item {
              width: 50%;
              padding: 0 20px;
            }
          }
        }

        .vision-img {
          width: 100%;
          height: 520px;
          background: #1A232F;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .vision-popup {
          left: 0;
          top: -132px;
          padding: 0 20px;
          height: 416px;
          background: #3672FD;
          position: absolute;
          z-index: 2;

          .vision-list {
            display: flex;
            margin-top: 132px;

            .vision-item {
              display: flex;
              flex-direction: column;
              position: relative;
              border-right: 1px solid #FFFFFF;
              padding: 0 48px;

              &:last-child {
                border: none;
              }

              .name {
                font-weight: bolder;
                font-size: 30px;
                color: #FFFFFF;
                line-height: 42px;
              }

              .en-name {
                font-weight: bold;
                font-size: 36px;
                color: #88ACFF;
                line-height: 47px;
                text-transform: uppercase;
              }

              .desc {
                font-weight: 400;
                font-size: 20px;
                color: #FFFFFF;
                line-height: 28px;
                text-align: center;
                margin-top: 40px;
              }
            }
          }
        }
      }
    }
  }
</style>