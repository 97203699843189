<template>
    <div class="service-box">
        <div class="service-content">
            <!-- <div class="service-left">
                <img src="@/assets/images/icon_logo.png">
            </div> -->
            <div class="service-right">
                <div class="right-content">
                    <div class="en-desc">Why use this service</div>
                    <div class="title">为什么使用时升数据的AI服务？</div>
                    <div class="desc">让人工智能帮助每个人提升工作效率，减轻压力。
                        <br>聚合最好的先进人工智能大模型，打造专属AI服务。
                        <br>独特的创意市场和社区，每个人都可以通过AI赚钱。
                        <br>通过AI让消费者把自己的想法和需求变成产品创意，打通买家和厂商的新通道。</div>
                    <el-popover placement="top" width="280" trigger="click">
                        <div class="">
                            <img src="@/assets/images/about/wechat_3.jpg">
                        </div>
                        <div slot="reference" class="service-btn">立即体验</div>
                    </el-popover>
                </div>
                
            </div>

        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="less" scoped>
.service-box {
    width: 100%;
    height: 514px;
    background: url("~@/assets/images/index/why.png") center no-repeat;
    background-size: 100% 100%;
    .service-content {
        width: 1200px;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        height: 100%;
        .service-left {
            display: flex;
            align-items: flex-end;
            img {
                width: 452px;
                height: 452px;
            }
        }
        .service-right {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: flex-end;
            .right-content {
                width: 600px;
                height: 390px;
                background: #1A232F;
                border-radius: 10px;
                backdrop-filter: blur(10px);
            }
            .en-desc {
                margin-top: 52px;
                font-weight: bold;
                font-size: 24px;
                color: #626A83;
                line-height: 32px;
                text-transform: uppercase;
                text-align: left;
                padding-left: 42px;
            }
            .title {
                font-weight: bold;
                font-size: 30px;
                color: #3672FD;
                line-height: 42px;
                text-align: left;
                padding-left: 42px;
            }
            .desc {
                font-weight: 400;
                font-size: 14px;
                color: #CCCFDA;
                line-height: 20px;
                text-align: right;
                margin-top: 20px;
                padding-right: 50px;
            }
            .service-btn {
                width: 150px;
                height: 40px;
                background: #3672FD;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 70px;
                font-weight: bold;
                font-size: 17px;
                color: #FFFFFF;
                cursor: pointer;
                margin-left: 42px;
            }
        }
    }
}
</style>