import request from '@/utils/request';

/**
 * 发送手机验证码短信
 * mobile :手机号
 */
 export function userVerifyCode(params) {
    return request({
        url: '/user/verify_code',
        method: 'post',
        data: params
    })
}

/**
 * 手机号登录：手机号+验证码登录
 * mobile 
 * code 
 */
 export function userMobileLogin(params) {
    return request({
        url: '/user/mobile_login',
        method: 'post',
        data: params
    })
}

/**
 * 当前登录用户 或 其他用户，需要传id
 * id
 */
 export function getUserInfo(id) {
	let url = "/user/info";
	if(id) {
		url = "/user/info?id=" + id;
	}
    return request({
        url: url,
        method: 'get',
    })
}