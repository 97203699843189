<template>
    <div class="community-box">
        <CommunityListCom :communityList="communityList"></CommunityListCom>
        <RankCom></RankCom>
    </div>
</template>
<script>
import CommunityListCom from "./communityList.vue";
import RankCom from "./rank.vue";
export default {
    props:["communityList"],
    components: {
        CommunityListCom,
        RankCom
    }
}
</script>
<style lang="less" scoped>
.community-box {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 70px;
    margin-bottom: 100px;
}
</style>