<template>
  <div class="container">
    <div class="center-content">
      <BannerCom></BannerCom>
      <SolutionCom></SolutionCom>
      <!-- <ServiceCom></ServiceCom> -->
      <!-- <CommunityCom :communityList="communityList"></CommunityCom> -->
      <SolutionOther></SolutionOther>
      <BottomCom></BottomCom>
    </div>

  </div>
</template>
<script>
import BannerCom from "@/components/banner/index.vue";
import SolutionCom from "@/components/solution/index.vue";
import ServiceCom from "@/components/service/index.vue";
import CommunityCom from "@/components/community/index.vue";
import BottomCom from "@/components/bottom/index.vue";
import SolutionOther from "@/components/solution/other.vue";
import { getPostList } from "@/api/main";

export default {
  components: {
    BannerCom,
    SolutionCom,
    ServiceCom,
    CommunityCom,
    BottomCom,
    SolutionOther
  },
  data() {
    return {
        communityList: [],
        params: {
            sort: "desc",
            offset: 0,
            limit: 20,
            sort_by: "created_at"
        },
    };
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.getPostListReq();
    this.$bus.$off("initData");
    this.$bus.$on("initData",item => {
        if(item) {
            this.getPostListReq();
        } else {
            this.communityList = [];
        }
    })
  },
  methods: {
    getPostListReq() {
      getPostList(this.params)
        .then((res) => {
          if (res.error == 0 && res.data && res.data.list) {
            this.communityList = res.data.list || [];
          } else {
            this.communityList = [];
          }
        })
        .catch((error) => {
          this.communityList = [];
        });
    }
  },
};
</script>
<style lang="less" scoped>
.container {
  //   height: 100%;
  display: flex;
  /* 禁止文字选择 */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #f6f5f8;
  .center-content {
    padding-top: 128px;
    width: 100%;
  }
}
</style>