<template>
    <div class="rank-box">
        <SubTitle :title="title" :enTitle="enTitle"></SubTitle>
        <div class="rank-content">
            <div class="rank-h">
                <div class="rank-num">
                    排名
                </div>
                <!-- <div class="avatar">头像</div> -->
                <div class="name">用户昵称</div>
                <div class="count">累计收入</div>
            </div>
            <div class="rank-list">
                <div class="rank-item" v-for="(item,idx) in rankList" :key="idx">
                    <div class="rank-num">
                        <div class="num-img" v-if="idx == 0">
                            <img src="@/assets/images/index/no1.png">
                        </div>
                        <div class="num-img" v-if="idx == 1">
                            <img src="@/assets/images/index/no2.png">
                        </div>
                        <div class="num-img" v-if="idx == 2">
                            <img src="@/assets/images/index/no3.png">
                        </div>
                        <div class="num" v-if="idx > 2">{{idx + 1}}</div>
                    </div>
                    <!-- <div class="avatar">
                        <img :src="item.avatar">
                    </div> -->
                    <div class="name">
                        <div class="text one-text">{{item.name}}</div>
                    </div>
                    <div class="count">
                        <div class="text">¥ {{item.count}}</div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import SubTitle from "../subTitle/index.vue";
export default {
    components: {
        SubTitle
    },
    data() {
        return {
            title: "创意社区收入排行榜",
            // enTitle: "Income ranking",
            enTitle: "",
            rankList: [{
                name: '悠悠球',
                avatar: require("@/assets/images/default_avatar.png"),
                count: "314.28"
            },{
                name: '鸭梨山大',
                avatar: require("@/assets/images/default_avatar.png"),
                count: "234.68"
            },{
                name: '小小酱',
                avatar: require("@/assets/images/default_avatar.png"),
                count: "214"
            },{
                name: 'Lucas',
                avatar: require("@/assets/images/default_avatar.png"),
                count: "194"
            },{
                name: '南方小土豆',
                avatar: require("@/assets/images/default_avatar.png"),
                count: "120.98"
            },{
                name: '北方的狼',
                avatar: require("@/assets/images/default_avatar.png"),
                count: "58.48"
            },{
                name: '叶天帝',
                avatar: require("@/assets/images/default_avatar.png"),
                count: "20.18"
            },{
                name: '二秃子',
                avatar: require("@/assets/images/default_avatar.png"),
                count: "16.00"
            }]
        }
    }
}
</script>
<style lang="less" scoped>
.rank-box {
    width: 600px;
    display: flex;
    flex-direction: column;
    .rank-content {
        background: #FBFBFC;
        border-radius: 10px;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        .rank-h {
            font-weight: bold;
            font-size: 17px;
            color: #626A83;
            line-height: 70px;
            display: flex;
        }
        .rank-num {
            width: 110px;
            display: flex;
            justify-content: center;
        }
        .avatar {
            width: 120px;
            display: flex;
            justify-content: center;
            img {
                width: 48px;
                height: 48px;
                border-radius: 100%;
            }
        }
        .name {
            display: flex;
            justify-content: center;
            width: 150px;
            .text {
                font-weight: 400;
                font-size: 14px;
                color: #1A232F;
            }
        }
        .count {
            display: flex;
            justify-content: center;
            width: 160px;
            .text {
                font-weight: bold;
                font-size: 14px;
                color: #1A232F;
            }
        }
        .num {
            width: 24px;
            height: 24px;
            background: #D9E5FF;
            border: 1px solid #88ACFF;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
        }
        .num-img {
            img {
                width: 32px;
                height: 32px;
            }
        }
        .rank-list {
            display: flex;
            flex-direction: column;
            .rank-item {
                display: flex;
                align-items: center;
                height: 69px;
                &:nth-child(2n+1) {
                    
                    background: #F3F3F8;
                    border-radius: 10px;
                }
            }
        }
    }
}
</style>