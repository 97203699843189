import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
import Layout from '@/layout'
import Home from "@/pages/Index.vue";
import Service from "@/pages/service/index.vue";
import Intelligent from "@/pages/intelligent/index.vue";
import Community from "@/pages/community/index.vue";
import About from "@/pages/about/index.vue";
import Privacy from "@/pages/privacy/index.vue";
import Rule from "@/pages/rule/index.vue";
let routes = [
  //   {
  //     path: "/",
  //     component: Home,
  //     name: "",
  //     hidden: true
  // },
  {
    path: '/404',
    component: () => import('@/pages/error/404'),
    hidden: true
  },
  {
    path: '/privacy',
    component: Privacy,
    name: 'Privacy',
    hidden: true
  },
  {
    path: '/rule',
    component: Rule,
    name: 'Rule',
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: "Index",
    hidden: true,
    children: [
      {
        path: '/Index',
        component: Home,
        name: 'Index',
        meta: { title: '首页' }
      },
      {
        path: '/Service',
        component: Service,
        name: 'Service',
        meta: { title: '企业服务' }
      },
      {
        path: '/Intelligent',
        component: Intelligent,
        name: 'Intelligent',
        meta: { title: '人工智能' }
      },
      // {
      //   path: '/Community',
      //   component: Community,
      //   name: 'Community',
      //   meta: { title: '创意社区' }
      // },
      {
        path: '/About',
        component: About,
        name: 'About',
        meta: { title: '关于我们' }
      },
    ]
  }
]

let routerPush = Router.prototype.push;
let routerReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch(err => err)
}

export default routes;