<template>
    <div class="join-box">
        <div class="join-center">
            <SubTitle :title="title" :enTitle="enTitle">
            </SubTitle>
            <div class="join-content">
                <div class="join-img">
                    <img src="@/assets/images/about/guanyu-banner.png">
                </div>
                <div class="join-info">
                    <div class="opt">
                        <div class="btn">立即投递</div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import SubTitle from "../subTitle/index.vue";
    export default {
        components: {
            SubTitle,
        },
        data() {
            return {
                title: "加入我们",
                // enTitle: "Join US",
                enTitle: "",
                joinList: [{
                    name: "产品经理",
                    location: "上海"
                }, {
                    name: "开发工程师",
                    location: "上海"
                }, {
                    name: "算法工程师",
                    location: "上海"
                }, {
                    name: "财务主管",
                    location: "上海"
                }]
            };
        },
    };
</script>
<style lang="less" scoped>
    .join-box {
        width: 100%;
        margin-top: 80px;
        margin-bottom: 80px;
        position: relative;

        .join-center {
            width: 1200px;
            display: flex;
            margin: 0 auto;
            flex-direction: column;

            .join-content {
                width: 100%;
                display: flex;
                position: relative;
                margin-top: 50px;
                justify-content: space-between;

                .join-list {
                    display: flex;
                    flex-direction: column;

                    .join-item {
                        width: 570px;
                        background: #FFFFFF;
                        border-radius: 10px;
                        padding: 30px 30px 25px 30px;
                        position: relative;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 10px;

                        .name {
                            font-weight: bold;
                            font-size: 24px;
                            color: #1A232F;
                            line-height: 33px;
                            text-align: left;
                        }

                        .location {
                            font-weight: 400;
                            font-size: 16px;
                            color: #626A83;
                            line-height: 22px;
                            text-align: left;
                            margin-top: 10px;
                        }

                        .btn {
                            position: absolute;
                            right: 30px;
                            bottom: 25px;
                            font-weight: 400;
                            font-size: 16px;
                            color: #626A83;
                            line-height: 22px;
                        }
                    }
                }

                .join-img {
                    flex: 1;

                    img {
                        width: 100%;
                        height: 300px;
                        border-radius: 20px;
                    }
                }

                .join-info {
                    width: 600px;


                    .opt {
                        display: flex;
                        justify-content: center;
                        margin-top: 147px;

                        .btn {
                            width: 263px;
                            height: 68px;
                            background: #3672FD;
                            border-radius: 10px;
                            font-weight: bold;
                            font-size: 28px;
                            color: #FFFFFF;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                        }
                    }

                }
            }
        }
    }
</style>