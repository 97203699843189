<template>
    <div class="banner-box">
        <div class="banner-content">
            <div class="banner-title">关于我们</div>
            <!-- <div class="banner-desc">时升数据AI</div> -->
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="less" scoped>
.banner-box {
    width: 100%;
    height: 555px;
    background: url("~@/assets/images/about/guanyu-banner.png") center center no-repeat;
    background-size: 100% 100%;
    .banner-content {
        width: 1200px;
        display: flex;
        margin: 0 auto;
        height: 100%;
        flex-direction: column;
        .banner-title {
            font-weight: bold;
            font-size: 60px;
            color: #1A232F;
            line-height: 84px;
            text-align: center;
            margin-top: 112px;
        }
        .banner-desc {
            font-weight: 400;
            font-size: 24px;
            color: #626A83;
            line-height: 33px;
            letter-spacing: 20px;
            text-align: center;
            margin-top: 32px;
        }
    }
}
</style>