<template>
  <div class="partner-box">
    <div class="partner-center">
      <SubTitle :title="title" :enTitle="enTitle">
      </SubTitle>
      <div class="partner-content">
        <div class="partner-list">
            <div class="partner-item" v-for="(item,idx) in partnerList" :key="idx">
                <img :src="item.imgSrc">
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SubTitle from "../subTitle/index.vue";
export default {
  components: {
    SubTitle,
  },
  data() {
    return {
      title: "合作伙伴",
      // enTitle: "Partner",
      enTitle: "",
      partnerList: [{
        imgSrc: require("@/assets/images/about/huoban1.png")
      },{
        imgSrc: require("@/assets/images/about/huoban10.png")
      },{
        imgSrc: require("@/assets/images/about/huoban9.png")
      },
      // {
      //   imgSrc: require("@/assets/images/about/huoban4.png")
      // }
      // ,{
      //   imgSrc: require("@/assets/images/about/huoban5.png")
      // },{
      //   imgSrc: require("@/assets/images/about/huoban6.png")
      // },{
      //   imgSrc: require("@/assets/images/about/huoban7.png")
      // },{
      //   imgSrc: require("@/assets/images/about/huoban8.png")
      // }
    ]
    };
  },
};
</script>
<style lang="less" scoped>
.partner-box {
  width: 100%;
  margin-top: 80px;
  position: relative;
  .partner-center {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    .partner-content {
      width: 100%;
      display: flex;
      position: relative;
      .partner-list {
        width: 1230px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;
        overflow-x:hidden;
        .partner-item {
            width: 280px;
            height: 120px;
            background: #FFFFFF;
            border-radius: 5px;
            margin-right: 25px;
            margin-bottom: 30px;
            &:nth-child(4n) {
                margin-right: 0;
            }
            img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
            }
        }
      }
    }
  }
}
</style>