<template>
  <div class="banner-box">
    <div class="banner-content">
      <div class="banner-title">创意社区</div>
      <div class="banner-desc">能学习/可分享/赚零钱</div>
      <div class="banner-btn">
        <!-- <div class="btn">立即体验</div> -->
        <el-popover placement="right" width="280"
          trigger="click">
          <div class="">
            <img src="@/assets/images/about/wechat_3.jpg">
          </div>
          <div slot="reference" class="btn">立即体验</div>
        </el-popover>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.banner-box {
  width: 100%;
  height: 555px;
  background: url("~@/assets/images/shequ_banner.png") center center no-repeat;
  background-size: 100% 100%;
  .banner-content {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    height: 100%;
    flex-direction: column;
    .banner-title {
      font-weight: bold;
      font-size: 60px;
      color: #1a232f;
      line-height: 84px;
      text-align: center;
      margin-top: 112px;
    }
    .banner-desc {
      font-weight: 400;
      font-size: 24px;
      color: #626a83;
      line-height: 33px;
      letter-spacing: 20px;
      text-align: center;
      margin-top: 32px;
    }
    .banner-btn {
      margin-top: 64px;
      display: flex;
      justify-content: center;
      .btn {
        width: 263px;
        height: 68px;
        background: #3672fd;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 28px;
        color: #ffffff;
      }
    }
  }
}
</style>