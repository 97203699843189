<template>
    <div class="menu-wrap" :class="[child ? 'menu-child-wrap' : '']">
        <template v-for="(item,idx) in menuList">
            <el-submenu  :class="[menuIdx == item.index ? 'active' : '']" v-if="item.children && item.children.length>0" :key="item.index" :index="item.index">
                <template #title>
                    <span >{{item.name}}</span>
                </template>
                <MenuTree :menuList="item.children" :child="true"></MenuTree>
            </el-submenu>
            <el-menu-item :class="[menuIdx == item.index ? 'active' : '']" v-if="!item.children" :key="item.index" :index="item.index">
                <span>{{item.name}}</span>
            </el-menu-item>
        </template>
    </div>
</template>
<script>
    export default {
        props: {
            menuList: {
                type: Array,
                default() {
                    return []
                }
            },
            child: {
                type: Boolean,
                default() {
                    return false;
                }
            },
            menuIdx: {
                type: String,
                default() {
                    return "1"
                }
            }
        },
        name: 'MenuTree',
        components: {
        },
        methods: {
        }
    }
</script>
<style lang="less" scoped>
    .menu-wrap {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        .el-menu-item,
        .el-submenu {
            padding: 0 32px;
            height: 128px;
            font-weight: 400;
            font-size: 20px;
            color: #1a232f;
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;

            &.active {
                color: #3672fd;

                &::after {
                    position: absolute;
                    left: 40px;
                    right: 40px;
                    height: 4px;
                    background: #3672fd;
                    border-radius: 2px;
                    content: "";
                    bottom: 40px;
                }
            }
            &.is-active {
                color: #3672fd;

                &::after {
                    position: absolute;
                    left: 40px;
                    right: 40px;
                    height: 4px;
                    background: #3672fd;
                    border-radius: 2px;
                    content: "";
                    bottom: 40px;
                }
            }
        }


        .el-submenu__icon-arrow {
            display: none;
        }

        .el-submenu__title {
            height: 128px;
            font-weight: 400;
            font-size: 20px;
            color: #1a232f;
        }

        ::v-deep  .el-submenu__title {
            height: 128px;
            font-weight: 400;
            font-size: 20px;
            color: #1a232f;
            display: flex;
            align-items: center;
            cursor: pointer;
            .el-submenu__icon-arrow  {
                display: none;
            }
        }
    }
    .menu-child-wrap {
        display: flex;
        align-content: center;
        flex-direction: column;
        .el-menu-item,
        .el-submenu {
            padding: 0 32px;
            height: 60px;
            font-weight: 400;
            font-size: 20px;
            color: #1a232f;
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            &:hover {
                background-color: #ecf5ff;
            }

            &.active {
                color: #3672fd;

                &::after {
                    position: absolute;
                    left: 20px;
                    right: 20px;
                    height: 4px;
                    background: #3672fd;
                    border-radius: 2px;
                    content: "";
                    bottom: 2px;
                }
            }
            &.is-active {
                color: #3672fd;

                &::after {
                    position: absolute;
                    left: 20px;
                    right: 20px;
                    height: 4px;
                    background: #3672fd;
                    border-radius: 2px;
                    content: "";
                    bottom: 2px;
                }
            }
        }

    }
</style>