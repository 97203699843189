<template>
    <div class="bi-box">
        <SecondTitle :secTitle="secTitle" :titleImg="titleImg"></SecondTitle>
        <div class="bi-list">
            <div class="bi-item" v-for="(item,idx) in biList" :key="idx">
                <div class="bi-left" v-if="idx % 2 == 0">
                    <div class="bi-img">
                        <img :src="item.imgSrc">
                    </div>
                </div>
                <div class="bi-right">
                    <div class="name">
                        {{item.name}}
                    </div>
                    <div class="desc" v-html="item.desc"></div>
                </div>
                <div class="bi-left bi-img-right" v-if="idx % 2 == 1">
                    <div class="bi-img">
                        <img :src="item.imgSrc">
                    </div>
                </div>
            </div>
        </div>
        <div class="bi-opt">
            <!-- <div class="bi-btn">立即体验</div> -->
        </div>
        
    </div>
</template>
<script>
import SecondTitle from "@/components/secondTitle/index.vue";
export default {
    components: {
        SecondTitle
    },
    data() {
        return {
            secTitle: "行业BI——投产决策助手",
            titleImg: require("@/assets/images/logo_blue.png"),
            biList: [{
                name: "决策分析",
                desc: "服务于企业决策者，助力企业快速、周全、科学决策。<br>洞悉行业、品类热点和趋势。随时掌握市场动向。<br>大数据：涵盖销售数据、IP数据、用户需求等多个层面。<br>科学算法：处理、分析、统计与预测，让信息和数据发挥最大价值。<br>经验辅助：时升决策分析系统辅助决策者自身经验实现高效决策。",
                imgSrc: require("@/assets/images/index/pinleifenxi.png")
            },{
                name: "IP分析",
                desc: "通过大数据和人工智能的结合为企业选择IP、使用IP、IP营销提供数据依据和理论支持。<br>通过IP影响力数据、基本属性数据、角色特征数据相结合，打造全方位IP决策建议。",
                imgSrc: require("@/assets/images/index/chanpincelue.png")
            },{
                name: "产品策略",
                desc: "时升BI辅助制定具体的产品投产策略。<br>价格策略：透视不同价格区间的市场接受度，达到利润最大化。<br>IP策略：辅助决策者选择最适合的IP，并详细掌握IP背后的人群。<br>蓝海指数：通过供需比辅助决策者进入红海竞争或者挖掘蓝海机会。",
                imgSrc: require("@/assets/images/index/IPfenxi.png")
            }]
        }
    }
}
</script>
<style lang="less" scoped>
.bi-box {
    width: 550px;
    display: flex;
    flex-direction: column;
    .bi-list {
        .bi-item {
            display: flex;
            padding: 35px 0;
            border-bottom: 1px solid #CCCFDA;
            &:last-child {
                border-bottom: none;
            }
            .bi-left,.bi-right {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .bi-left {
                padding-right: 30px;
            }
            .bi-img-right {
                padding-right: 0;
                padding-left: 30px;
            }
            .name {
                font-weight: bold;
                font-size: 17px;
                color: #1A232F;
                line-height: 24px;
                text-align: left;
            }
            .desc {
                font-weight: 400;
                font-size: 14px;
                color: #626A83;
                line-height: 20px;
                margin-top: 7px;
                text-align: left;
            }
            .bi-img {
                img {
                    width: 95px;
                    height: 95px;
                }
            }
        }
    }
    .bi-opt {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        .bi-btn {
            width: 150px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 10px;
            border: 1px solid #979DB1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 17px;
            color: #626A83;
            cursor: pointer;
        }
        
    }
}
</style>