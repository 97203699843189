<template>
  <div class="content-box">
    <div class="content-center">
      <SubTitle :title="title" :enTitle="enTitle">
      </SubTitle>
      <div class="content-list">
        <div class="content-item cursor"
          v-for="(item,idx) in contentList" :key="idx">
          <div class="content-left">
            <!-- <div class="content-img" v-if="item.imgSrc">
              <img :src="item.imgSrc">
            </div> -->
            <div class="content-info">
              <div class="name">{{item.title || ""}}</div>
              <!-- <div class="desc"
                v-if="!item.imgSrc && item.desc">
                {{item.desc}}</div> -->
              <div class="bottom">
                <div class="create-time">{{item.created_at}}
                </div>
                <div class="fav-box">

                  <img src="@/assets/images/index/good.png">
                  <div class="fav">{{item.like}}</div>
                </div>
              </div>

            </div>
          </div>
          <div class="content-right">
            <img class="avatar" v-if="item.user && item.user.avatar" :src="item.user.avatar">
            <img v-else src="https://files.sssrise.com/files/uploads/static/default_avatar.png">
            <div class="user-name one-text">
              {{item.user && item.user.nickname || ""}}</div>
            <div class="bade-box">
              <img class="bade-img"
                src="https://files.sssrise.com/files/uploads/static/cyhz.png">
              <img class="bade-img"
                src="https://files.sssrise.com/files/uploads/static/zshz.png">
              <img class="bade-img"
                src="https://files.sssrise.com/files/uploads/static/xzhz.png">
            </div>
          </div>
          <el-popover placement="top" width="280" trigger="click">
              <div class="">
                  <img src="@/assets/images/about/wechat_3.jpg">
              </div>
              <div slot="reference" style="position: absolute;top: 0;right:0;top:0;left:0;bottom:0;"></div>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SubTitle from "../subTitle/index.vue";
export default {
  props: ["title", "enTitle", "contentList"],
  components: {
    SubTitle,
  },
};
</script>
<style lang="less" scoped>
.content-box {
  width: 100%;
  margin-top: 30px;
  .content-center {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    .content-list {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      .content-item {
        display: flex;
        margin-bottom: 20px;
        
        background: #fff;
        border-radius: 10px;
        position: relative;
        .content-left {
          width: 960px;
          display: flex;
          padding: 20px;
          .content-img {
            padding-right: 20px;
            img {
              width: 140px;
              height: 140px;
              border-radius: 10px;
            }
          }
          .content-info {
            display: flex;
            flex-direction: column;
            flex: 1;
            .name {
              font-weight: 400;
              font-size: 14px;
              color: #1a232f;
              line-height: 20px;
              text-align: left;
            }
            .desc {
              font-weight: 400;
              font-size: 12px;
              color: #626a83;
              line-height: 17px;
              margin-top: 7px;
              text-align: left;
            }
            .bottom {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              flex: 1;
                padding-bottom: 6px;

              .create-time {
                font-weight: 400;
                font-size: 10px;
                color: #979db1;
              }
              .fav-box {
                display: flex;
                img {
                  width: 14px;
                  height: 14px;
                }
                .fav {
                  font-weight: 400;
                  font-size: 12px;
                  color: #626a83;
                  line-height: 17px;
                  margin-left: 8px;
                }
              }
            }
          }
        }
        .content-right {
          width: 240px;
          background: #FBFBFC;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 20px;
          .avatar {
            width: 64px;
            height: 64px;
            border-radius: 100%;
          }
          .user-name {
            font-weight: 400;
            font-size: 18px;
            color: #1A232F;
            line-height: 25px;
            margin-top: 5px;
          }
          .bade-box {
            display: flex;
            margin-top: 4px;
          }
          .bade-img {
            width: 20px;
            height: 20px;
            margin-left: 2px;
          }
        }
      }
    }
  }
}
</style>