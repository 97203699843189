import request from '@/utils/request';
/**
 * 获取创意市场发布内容列表
 * offset
 * limit
 * sort:排序规则 asc正序 desc倒序
 * sort_by:排序字段 created_at发布时间 like点赞量 price价格
 */
export function getPostList(params) {
    return request({
        url: "/post/list",
        method: 'get',
        params: params
    })
}

/**
 * 获取聊天大语言模型列表
 * offset
 * limit
 */
 export function getChatModels(params) {
    return request({
        url: "/chat/models",
        method: 'get',
        params: params
    })
}
