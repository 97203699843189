<template>
  <div class="contact-box">
    <div class="contact-center">
      <SubTitle :title="title" :enTitle="enTitle">
      </SubTitle>
      <div class="contact-content">
        <div class="map">
          <img
            src="@/assets/images/about/ditu.png">
        </div>
        <div class="contact-info">
          <div class="contact-item">
            <div class="contact-img">
              <img src="@/assets/images/about/dizhi.png">
            </div>
            <div class="detail">
              <div class="name">地址：上海市闵行区剑川路955号</div>
            </div>
          </div>
          <div class="contact-item">
            <div class="contact-img">

            </div>
            <div class="detail">
              <div class="name">邮政编码：201109</div>
            </div>
          </div>
          <div class="contact-item">
            <div class="contact-img">
              <img src="@/assets/images/about/youxiang.png">
            </div>
            <div class="detail">
              <div class="name">电子邮箱：sssrise@sssrise.com
              </div>
            </div>
          </div>
          <div class="code-box">
            <div class="code-list">
              <div class="code-item"
                v-for="(item,idx) in codeList" :key="idx">
                <div class="desc">{{item.name}}</div>
                <img :src="item.imgSrc">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SubTitle from "../subTitle/index.vue";
export default {
  components: {
    SubTitle,
  },
  data() {
    return {
      title: "联系我们",
      // enTitle: "contact US",
      enTitle: "",
      codeList: [
        // {
        //   name: "企业微信",
        //   imgSrc: require("@/assets/images/about/wechat_1.png"),
        // },
        // {
        //   name: "公众号",
        //   imgSrc: require("@/assets/images/about/wechat_2.png"),
        // },
        {
          name: "AI小程序",
          imgSrc: require("@/assets/images/about/wechat_3.jpg"),
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.contact-box {
  width: 100%;
  padding-top: 70px;
  padding-bottom: 80px;
  position: relative;
  background: #fff;
  .contact-center {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    .contact-content {
      width: 100%;
      display: flex;
      position: relative;
      margin-top: 50px;
      .map {
        padding-right: 70px;
        img {
          width: 600px;
          height: 440px;
          border-radius: 10px;
        }
      }
      .contact-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        .contact-item {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          .contact-img {
            width: 44px;

            i {
              width: 44px;
              height: 44px;
              color: #3672fd;
              font-size: 44px;
            }
            img {
              width: 44px;
              height: 44px;
            }
          }
          .detail {
            padding-left: 22px;
            .name {
              font-size: 20px;
              color: #626a83;
            }
          }
        }
        .code-box {
          display: flex;
          flex: 1;
          // justify-content: flex-end;
          align-items: flex-end;
          .code-list {
            display: flex;
            .code-item {
              display: flex;
              flex-direction: column;
              margin-right: 48px;
              &:last-child {
                margin-right: 0;
              }
              .desc {
                font-weight: 400;
                font-size: 20px;
                color: #626A83;
                line-height: 28px;
              }
              img {
                width: 120px;
                height: 120px;
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>