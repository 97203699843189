<template>
  <div class="login-register-box">
    <div class="login-register-content">
      <i class="el-icon-circle-close icon-defalut-close cursor"
        @click="closeLogin"></i>
      <div class="default-title">
        {{isRegister ? "注册" : "登录"}}</div>
      <img src="@/assets/images/logo_blue.png">
      <div class="form-box">
        <el-form class="form-list" :model="form"
          :rules="rules" ref="form">
          <el-form-item class="form-item"
            prop="phoneNumber">
            <div class="form-desc">手机号码</div>
            <el-input class="form-input"
              v-model="form.phoneNumber"
              placeholder="请输入手机号"
              oninput="if(value.length>11)value=value.slice(0,11)"
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"></el-input>
          </el-form-item>
          <el-form-item class="form-item" prop="code">
            <div class="form-desc">验证码</div>
            <el-input class="form-input" v-model="form.code"
              placeholder="请输入验证码"
              oninput="if(value.length>11)value=value.slice(0,6)"
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');">
            </el-input>
            <div class="code-box"
              :class="[countdown == 0 ? 'cursor' : '']"
              @click="getCode">
              {{ countdown > 0 ? `${countdown}秒后重新获取` : '获取验证码' }}
            </div>
          </el-form-item>
          <div class="login-btn cursor" @click="login">
            {{isRegister ? "注册" : "登录"}}</div>
        </el-form>

        <div class="register-btn cursor" v-if="!isRegister"
          @click="register">注册</div>
      </div>
      <div class="bottom-box">
        <!-- <div class="third-box">
          <span class="border"></span>
          <div class="third-title">第三方登录</div>
          <span class="border"></span>
        </div>
        <div class="third-list">
          <div class="third-item"
            v-for="(item,idx) in thirdList" :key="idx">
            <img class="cursor" :src="item.imgSrc">
          </div>
        </div> -->
        <div class="privacy-box cursor"
          @click="choosePrivacy">
          <i class="el-icon-circle-check"
            v-if="isPrivacyChoose"></i>
          <i v-else class="icon-privacy"></i>
          阅读并接受<router-link target="_blank" :to="{path:'/privacy'}">《服务协议》</router-link>
          <router-link target="_blank" :to="{path:'/rule'}">《隐私政策》</router-link>
          <router-link target="_blank" :to="{path:'/rule'}">《知识产权保护协议》</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { userVerifyCode } from "@/api/login";
const TIME_COUNT = 60;
export default {
  data() {
    return {
      hintShow: false, // 提示语显示
      hint: "", // 提示语
      phone: "",
      code: "",
      form: {
        phoneNumber: "",
        code: "",
      },
      countdown: 0,
      rules: {
        phoneNumber: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
          {
            pattern: /^\d{4}$/,
            message: "请输入正确的验证码",
            trigger: "blur",
          },
        ],
      },
      thirdList: [
        // {
        //   type: 1,
        //   imgSrc: require("@/assets/images/wechat.png"),
        // },
        // {
        //   type: 2,
        //   imgSrc: require("@/assets/images/zfb.png"),
        // },
        // {
        //   type: 3,
        //   imgSrc: require("@/assets/images/zfb.png"),
        // },
      ],
      isPrivacyChoose: true,
      isRegister: false,
      timer: null,
    };
  },
  created() {
    this.isPrivacyChoose = true;
    this.isRegister = false;
  },
  methods: {
    getCode() {
      if (this.countdown > 0) {
        return;
      }
      this.$refs.form.validateField("phoneNumber", (valid) => {
        if (!valid) {
          userVerifyCode({
            mobile: this.form.phoneNumber,
          })
            .then((res) => {
              console.log("res", res);
              if (res.error == 0) {
                this.$message.success("获取验证码成功");
                if (!this.timer) {
                  this.countdown = TIME_COUNT;
                  this.timer = setInterval(() => {
                    if (this.countdown > 0 && this.countdown <= TIME_COUNT) {
                      this.countdown--;
                    } else {
                      clearInterval(this.timer);
                      this.timer = null;
                    }
                  }, 1000);
                }
              } else {
                this.$message.error((res && res.msg) || "获取验证码失败，请稍后重试");
              }
            })
            .catch((err) => {
              this.$message.error(
                (err && err.msg) || "获取验证码失败，请稍后重试"
              );
            });

          console.log("验证成功");
        } else {
          console.log("验证失败");
        }
      });
    },
    choosePrivacy() {
      this.isPrivacyChoose = !this.isPrivacyChoose;
    },
    register() {
      this.isRegister = !this.isRegister;
    },
    login() {
      if (!this.isPrivacyChoose) {
        this.$message.error("请先同意协议");
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 验证通过，提交表单
          console.log("表单通过验证，提交数据:", this.form);
          this.$store
            .dispatch("Login", this.form)
            .then(() => {
              this.$message.success("登录成功");
              this.$emit("closeLogin");
              this.$emit("initData");
            })
            .catch((err) => {
              this.$message.error((err && err.msg) || "登录失败，请稍后重试");
            });
        } else {
          console.log("表单验证失败");
          return false;
        }
      });
    },
    closeLogin() {
      this.$emit("closeLogin");
    }
  },
};
</script>
<style lang="less" scoped>
.login-register-box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: transparent;
  z-index: 9999;
  .login-register-content {
    width: 380px;
    position: absolute;
    background: #fff;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // bottom: 20px;
    z-index: 10000;
    border: 1px solid #f8f8fa;
    display: flex;
    flex-direction: column;

    img {
      width: 240px;
      height: 192;
      border-radius: 20px;
      margin: 0 auto;
      margin-top: 40px;
    }
    .form-box {
      flex: 1;
    }
    .form-list {
      margin-top: 44px;
      padding-left: 40px;
    }
    .form-item {
      position: relative;
      margin-bottom: 16px;
      width: 300px;
      &:last-child {
        margin-bottom: 0;
      }
      .form-desc {
        font-weight: 400;
        font-size: 14px;
        color: #626a83;
        line-height: 20px;
        text-align: left;
      }
      .form-input {
        margin-top: 8px;
        display: flex;
        width: 300px;
        height: 40px;
        box-shadow: 0px 2px 4px 0px #e7e8ed;
        border-radius: 24px;
        background: #fbfbfc;
        position: relative;
        ::v-deep .el-input__inner {
          width: 100%;
          height: 40px;
          border-radius: 24px;
          border: 1px solid #e7e8ed;
          // background: #FBFBFC;
          font-weight: 400;
          font-size: 14px;
          color: #979db1;
          padding-left: 20px;
        }
        input {
          width: 100%;
          height: 40px;
          border-radius: 24px;
          border: 1px solid #e7e8ed;
          // background: #FBFBFC;
          font-weight: 400;
          font-size: 14px;
          color: #979db1;
          padding-left: 20px;
        }
        input:focus {
          outline: none;
        }
      }
      .code-box {
        padding-right: 20px;
        text-align: right;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
        font-weight: 400;
        font-size: 12px;
        color: #3672fd;
        line-height: 40px;
      }
    }
    .login-btn {
      width: 300px;
      height: 40px;
      background: #3672fd;
      box-shadow: 0px 2px 4px 0px #d9e5ff;
      border-radius: 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
    }
    .register-btn {
      width: 300px;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px #e7e8ed;
      border-radius: 27px;
      border: 1px solid #e7e8ed;
      margin: 0 auto;
      margin-top: 10px;
      font-weight: 400;
      font-size: 14px;
      color: #626a83;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .bottom-box {
      padding-bottom: 22px;
      padding-left: 20px;
      .third-box {
        display: flex;
        align-items: center;
        justify-content: center;
        .border {
          width: 106px;
          height: 1px;
          background: rgba(204, 207, 218, 0.6);
        }
        .third-title {
          font-weight: 400;
          font-size: 12px;
          color: #979db1;
          line-height: 17px;
          padding: 0 14px;
        }
      }
      .third-list {
        margin-top: 18px;
        display: flex;
        justify-content: center;
        .third-item {
          margin-right: 36px;
          display: flex;
          &:last-child {
            margin-right: 0;
          }
          img {
            width: 48px;
            height: 48px;
            border-radius: 100%;
            margin-top: 0;
          }
        }
      }
      .privacy-box {
        margin-top: 57px;
        font-weight: 400;
        font-size: 12px;
        color: #979db1;
        line-height: 16px;
        display: flex;
        align-items: center;
        i {
          font-size: 20px;
          color: #3672fd;
          margin-right: 10px;
        }
        .icon-privacy {
          width: 20px;
          height: 20px;
          background: #ffffff;
          border: 1px solid #cccfda;
          display: inline-block;
          border-radius: 100%;
        }
        span {
          color: #3672fd;
        }
        a {
          text-decoration: none;
          color: #3672fd;
        }
      }
    }
  }
}
</style>