<template>
  <div id="app">
    <HeaderCom></HeaderCom>
    <router-view />
  </div>
</template>
<script>
import HeaderCom from "@/components/header/index.vue";
export default {
  components: {
    HeaderCom
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>
</style>