import { userMobileLogin,getUserInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: [],
    userInfo: null
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const code = userInfo.code;
      const mobile = userInfo.phoneNumber;
      return new Promise((resolve, reject) => {
        userMobileLogin({
          mobile: mobile,
          code: code,
        }).then((res) => {
            if (res.error == 0 && res.data && res.data.token) {
              setToken(res.data.token)
              commit('SET_TOKEN', res.data.token);
              commit("SET_USERINFO",res.data);
              resolve();
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(res => {
          if (res.error == 0 && res.data && res.data.id) {
            commit("SET_USERINFO",res.data);
            resolve(res.data);
          } else {
            reject(res);
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_USERINFO', "")
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default user
