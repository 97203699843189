import Vue from 'vue'
import App from './App.vue'
import "./assets/css/reset.css";
import "./assets/css/common.css";
import 'animate.css';
import VueRouter from "vue-router";
import routes from "./router/index";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import store from './store'

import { detectZoom } from '@/utils/detectZoom.js';
const m = detectZoom();
console.log("m",m)
// document.body.style.zoom = 100 / Number(m);

Vue.prototype.$bus = new Vue();


Vue.use(VueRouter);

Vue.config.productionTip = false

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // if (to.path == "/login") {
  //   sessionStorage.removeItem("user");
  // }
  // let user = sessionStorage.getItem("user");
  // if (!user && to.path != "/login") {
  //   next({
  //     path: "/login"
  //   })
  // } else {
  //   next()
  // }
  next()
})

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})