<template>
  <div class="container">
    <div class="center-content">
      <IntelligentBanner></IntelligentBanner>
      <ProductCom :modelsList="modelsList"></ProductCom>
      <BottomCom></BottomCom>
    </div>
  </div>
</template>
<script>
import IntelligentBanner from "@/components/banner/intelligentBanner.vue";
import BottomCom from "@/components/bottom/index.vue";
import ProductCom from "@/components/product/aiProduct.vue";
import { getChatModels } from "@/api/main";
export default {
  components: {
    BottomCom,
    ProductCom,
    IntelligentBanner,
  },
  data() {
    return {
      modelsList: [],
    };
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // this.getChatModelsReq();
    this.$bus.$off("initData");
    this.$bus.$on("initData", (item) => {
      if (item) {
        // this.getChatModelsReq();
      } else {
        this.modelsList = [];
      }
    });
  },
  mounted() {
  },
  methods: {
    getChatModelsReq() {
      getChatModels({
        offset: 0,
        limit: 100,
      })
        .then((res) => {
          if (res.error == 0 && res.data && res.data.list) {
            this.modelsList = res.data.list || [];
          } else {
            this.modelsList = [];
          }
        })
        .catch((error) => {
          this.modelsList = [];
        });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  //   height: 100%;
  display: flex;
  /* 禁止文字选择 */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #f6f5f8;
  .center-content {
    padding-top: 128px;
    width: 100%;
  }
}
</style>