<template>
  <div class="product-box">
    <div class="product-center">
      <SubTitle :title="title" :enTitle="enTitle">
      </SubTitle>
      <div class="product-list">
        <div class="product-item"
          v-for="(item,idx) in productList" :key="idx">
          <div class="product-img">
            <!-- <img :src="item.imgSrc"> -->
            <img
              :src="require('@/assets/images/ai/ai_1.png')">
          </div>
          <div class="product-info">
            <SecondTitle :secTitle="item.name">
            </SecondTitle>
            <!-- <div class="type">{{item.model_name}}</div> -->
            <!-- <div class="desc-title">模型描述</div> -->
            <div class="desc">{{item.desc}}</div>
            <div class="opt" v-if="item.type != 2">
              <!-- <div class="btn">立即体验</div> -->
              <el-popover placement="top" width="280"
                trigger="click">
                <div class="">
                  <img
                    src="@/assets/images/about/wechat_3.jpg">
                </div>
                <div slot="reference" class="btn">立即体验</div>
              </el-popover>
            </div>
          </div>
          <!-- <div class="product-img product-img-right"
            v-if="idx % 2 == 1">
            <img
              :src="require('@/assets/images/ai/ai_2.png')">
          </div> -->
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import SubTitle from "../subTitle/index.vue";
import SecondTitle from "@/components/secondTitle/index.vue";
export default {
  props: ["modelsList"],
  components: {
    SubTitle,
    SecondTitle,
  },
  data() {
    return {
      title: "AI产品",
      // enTitle: "AI products",
      enTitle: "",
      productList: [
        {
          name: "亲缘智伴（KIN AI）",
          typeName: "模型：文心一言4.0&GPT4.0",
          desc: "为玩具企业提供家庭育儿AI解决方案，快速打造企业专属面向目标用户的AI工具，通过AI小程序、种草内容、神奇创意和育儿科学，快速打造和转化私域流量，形成完整闭环。",
          imgSrc: require("@/assets/images/ai/ai_1.png"),
        },
        {
          name: "IP分析工具",
          typeName: "模型：文心一言4.0&GPT4.0",
          desc: "通过大数据和人工智能的结合为企业选择IP、使用IP、IP营销提供数据依据和理论支持。通过IP影响力数据、基本属性数据、角色特征数据相结合，打造全方位IP决策建议。",
          imgSrc: require("@/assets/images/ai/ai_2.png"),
        },
        {
          name: "玩具创意师",
          typeName: "模型：GPT4.0",
          desc: "通过AI大模型按照客户需求、产品亮点、IP元素、成本控制等要素智能创意玩具产品。",
          imgSrc: require("@/assets/images/ai/ai_3.png"),
        }
        ,{
            name: "产品优化师",
            typeName: "模型：ChatGPT 4.0",
            desc: "原有设计或旧产品基础上进行优化创作，快速完成产品迭代。",
            imgSrc: require("@/assets/images/ai/ai_3.png")
        }
        ,{
            name: "AI私人高定定制助手",
            typeName: "模型：ChatGPT 4.0",
            desc: "探索全新黄金定制时代，借助先进的AI技术，我们精心为您打造独一无二的私人定制黄金体验。让AI设计成为潮流的引领者，为您的黄金选择开启无限可能，让每一款黄金作品都闪耀着个性化的光芒，彰显您的非凡品味与独特魅力",
            imgSrc: require("@/assets/images/ai/ai_3.png"),
            type: 2
        }
      ],
      productList1: [
        {
          name: "亲缘智伴KIN AI",
          typeName: "模型：文心一言4.0&GPT4.0",
          desc: "基于文心一言4.0大模型和GPT4.0开发的。为您的宝贝提供全方位、个性化的育儿指导。AI助您科学育儿，轻松享受美好亲子时光！",
          imgSrc: require("@/assets/images/ai/ai_1.png"),
        },
        {
          id: "662650f0b3574d280cf41de4",
          ai_id: 1,
          model: "ernie-bot-4-parenting",
          model_name: "文心一言4.0",
          type: 0,
          name: "育儿专家文心一言4.0版",
          desc: "你作为一名育儿专家，你可以为我提供从新生儿到18岁儿童的全面育儿指导和支持。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/zs_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/zs_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "662650f0b3574d280cf41de5",
          ai_id: 0,
          model: "ai-4-parenting",
          model_name: "Chat GPT 4.0",
          type: 0,
          name: "育儿专家GPT4.0版",
          desc: "你作为一名育儿专家，你可以为我提供从新生儿到18岁儿童的全面育儿指导和支持。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/zs_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/zs_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "664c04e17f40e24121733fac",
          ai_id: 1,
          model: "ernie-bot-4-nutri",
          model_name: "文心一言4.0",
          type: 0,
          name: "儿童营养师文心一言4.0版",
          desc: "你是新生至18岁儿童营养的专业营养师，你提供儿童的健康成长全面的支持。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/zs_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/zs_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "664c04e67f40e24121733fad",
          ai_id: 0,
          model: "ai-4-nutri",
          model_name: "Chat GPT 4.0",
          type: 0,
          name: "儿童营养师GPT4.0版",
          desc: "你是新生至18岁儿童营养的专业营养师，你提供儿童的健康成长全面的支持。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/zs_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/zs_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "664c054a7f40e24121733fae",
          ai_id: 1,
          model: "ernie-bot-4-psycho",
          model_name: "文心一言4.0",
          type: 0,
          name: "心理咨询师文心一言4.0版",
          desc: "你是专业的儿童心理咨询师，提供全面的心理健康服务。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/zs_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/zs_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "664c05507f40e24121733faf",
          ai_id: 0,
          model: "ai-4-psycho",
          model_name: "Chat GPT 4.0",
          type: 0,
          name: "心理咨询师GPT4.0版",
          desc: "你是专业的儿童心理咨询师，提供全面的心理健康服务。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/zs_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/zs_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "664c05b17f40e24121733fb0",
          ai_id: 1,
          model: "ernie-bot-4-planner",
          model_name: "文心一言4.0",
          type: 0,
          name: "寓教于乐规划师文心一言4.0版",
          desc: "你是一名寓教于乐规划师，帮助儿童在游玩玩具的过程中，自然促进儿童的认知能力、社交能力、精细运动能力、创造力、想象力的发展。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/zs_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/zs_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "664c05b67f40e24121733fb1",
          ai_id: 0,
          model: "ai-4-planner",
          model_name: "Chat GPT 4.0",
          type: 0,
          name: "寓教于乐规划师GPT4.0版",
          desc: "你是一名寓教于乐规划师，帮助儿童在游玩玩具的过程中，自然促进儿童的认知能力、社交能力、精细运动能力、创造力、想象力的发展。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/zs_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/zs_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "662730fc8cfef838caf68508",
          ai_id: 1,
          model: "ernie-bot-4-vision-instructor",
          model_name: "文心一言4.0",
          type: 4,
          name: "玩具指导师文心一言4.0版",
          desc: "你是一名玩具指导师，你着眼于分析用户上传的玩具图片，挖掘每件玩具的潜在功能，并提供专业意见。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/wx_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/wx_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "662731218cfef838caf68509",
          ai_id: 0,
          model: "ai-4-vision-instructor",
          model_name: "Chat GPT 4.0",
          type: 4,
          name: "玩具指导师GPT4.0版",
          desc: "你是一名玩具指导师，你着眼于分析用户上传的玩具图片，挖掘每件玩具的潜在功能，并提供专业意见。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/wx_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/wx_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "66272c258cfef838caf68506",
          ai_id: 1,
          model: "ernie-bot-4-story",
          model_name: "文心一言 4.0",
          type: 1,
          name: "儿童故事专家文心一言4.0版",
          desc: "你是一名儿童故事专家，你专注于适合儿童的故事内容的创作和发散创新。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/wx_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/wx_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "66272c078cfef838caf68505",
          ai_id: 0,
          model: "ai-4-story",
          model_name: "Chat GPT 4.0",
          type: 1,
          name: "儿童故事专家GPT4.0版",
          desc: "你是一名儿童故事专家，你专注于适合儿童的故事内容的创作和发散创新。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/wx_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/wx_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "6627298b8cfef838caf684ff",
          ai_id: 1,
          model: "ernie-bot-4-vision-story",
          model_name: "文心一言4.0",
          type: 5,
          name: "儿童故事专家文心一言4.0版",
          desc: "你是一个儿童故事专家，您通过上传的图片来进行故事创作，为您的孩子量身定制一个专属的故事。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/wx_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/wx_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "662729a58cfef838caf68500",
          ai_id: 0,
          model: "ai-4-vision-story",
          model_name: "Chat GPT 4.0",
          type: 5,
          name: "儿童故事专家GPT4.0版",
          desc: "你是一个儿童故事专家，您通过上传的图片来进行故事创作，为您的孩子量身定制一个专属的故事。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/wx_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/wx_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "66272ba58cfef838caf68503",
          ai_id: 1,
          model: "ernie-bot-4-image-designer",
          model_name: "文心一言 4.0",
          type: 2,
          name: "玩具创意设计师文心一言4.0版",
          desc: "你作为一名充满热情的专业玩具创意设计师，你专注于将您的创新想法转化为孩子们心爱的玩具。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/icon_cy_black.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/icon_cy_blue.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "66272a9b8cfef838caf68501",
          ai_id: 0,
          model: "ai-4-image-designer",
          model_name: "Chat GPT 4.0",
          type: 2,
          name: "玩具创意设计师GPT4.0版",
          desc: "你作为一名充满热情的专业玩具创意设计师，你专注于将您的创新想法转化为孩子们心爱的玩具。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/icon_cy_black.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/icon_cy_blue.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "66272bc18cfef838caf68504",
          ai_id: 1,
          model: "ernie-bot-4-image-designer",
          model_name: "文心一言 4.0",
          type: 3,
          name: "玩具创意设计师文心一言4.0版",
          desc: "你作为一位有着丰富经验和创新思维的专业玩具创意设计师，你提供一项独特的服务：根据用户上传的图片，进行个性化的二次创作或彻底的重新设计。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/icon_cy_black.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/icon_cy_blue.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "66272add8cfef838caf68502",
          ai_id: 0,
          model: "ai-4-image-designer",
          model_name: "Chat GPT 4.0",
          type: 3,
          name: "玩具创意设计师GPT4.0版",
          desc: "你作为一位有着丰富经验和创新思维的专业玩具创意设计师，你提供一项独特的服务：根据用户上传的图片，进行个性化的二次创作或彻底的重新设计",
          default_image:
            "https://files.sssrise.com/files/uploads/static/icon_cy_black.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/icon_cy_blue.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "664c0c067f40e24121733fb3",
          ai_id: 1,
          model: "ernie-bot-4-encyclo",
          model_name: "文心一言4.0",
          type: 0,
          name: "儿童认知百科专家文心一言4.0版",
          desc: "你作为一位全面的儿童认知百科专家，你具备对世界上各种事物的深入了解。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/zs_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/zs_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "664c0c0c7f40e24121733fb4",
          ai_id: 0,
          model: "ai-4-encyclo",
          model_name: "Chat GPT 4.0",
          type: 0,
          name: "儿童认知百科专家GPT4.0版",
          desc: "你作为一位全面的儿童认知百科专家，你具备对世界上各种事物的深入了解。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/zs_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/zs_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "664c0c417f40e24121733fb5",
          ai_id: 1,
          model: "ernie-bot-4-stylist",
          model_name: "文心一言4.0",
          type: 0,
          name: "儿童造型师文心一言4.0版",
          desc: "你是一名专业的儿童造型师，你希望为你的小客户提供一次愉快且个性化的造型体验。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/zs_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/zs_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "664c0c447f40e24121733fb6",
          ai_id: 0,
          model: "ai-4-stylist",
          model_name: "Chat GPT 4.0",
          type: 0,
          name: "儿童造型师GPT4.0版",
          desc: "你是一名专业的儿童造型师，你希望为你的小客户提供一次愉快且个性化的造型体验。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/zs_defalut.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/zs_check.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "664c0cef7f40e24121733fb8",
          ai_id: 1,
          model: "ernie-bot-4-image",
          model_name: "文心一言 4.0",
          type: 2,
          name: "智能画图工具文心一言4.0版",
          desc: "你作为一个绘图工具，你可以帮助你完成各种绘画任务，包括具体的绘画技巧、材料推荐、风格发展指南等。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/icon_cy_black.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/icon_cy_blue.png",
          created_at: "2024-04-22 11:58:40",
        },
        {
          id: "664c0cc07f40e24121733fb7",
          ai_id: 0,
          model: "ai-4-image",
          model_name: "Chat GPT 4.0",
          type: 2,
          name: "智能画图工具GPT4.0版",
          desc: "你作为一个绘图工具，你可以帮助你完成各种绘画任务，包括具体的绘画技巧、材料推荐、风格发展指南等。",
          default_image:
            "https://files.sssrise.com/files/uploads/static/icon_cy_black.png",
          check_image:
            "https://files.sssrise.com/files/uploads/static/icon_cy_blue.png",
          created_at: "2024-04-22 11:58:40",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.product-box {
  width: 100%;
  margin-top: 60px;
  .product-center {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;

    .product-list {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      .product-item {
        display: flex;
        margin-bottom: 80px;
        cursor: pointer;
        .product-img {
          padding-right: 30px;
          img {
            width: 600px;
            height: 340px;
            border-radius: 10px;
          }
        }
        .product-img-right {
          padding-left: 30px;
          padding-right: 0;
        }
        .product-info {
          display: flex;
          flex-direction: column;
          flex: 1;
          .type {
            font-weight: bold;
            font-size: 17px;
            color: #1a232f;
            line-height: 24px;
            margin-top: 13px;
            padding-left: 16px;
            text-align: left;
          }
          .desc-title {
            font-weight: bold;
            font-size: 14px;
            color: #626a83;
            line-height: 20px;
            margin-top: 26px;
            padding-left: 16px;
            text-align: left;
          }
          .desc {
            font-weight: 400;
            font-size: 14px;
            color: #626a83;
            line-height: 20px;
            text-align: justify;
            font-style: normal;
            margin-top: 10px;
            padding-left: 16px;
          }
          .opt {
            display: flex;
            /* justify-content: flex-end; */
            flex: 1;
            align-items: flex-end;
            .btn {
              width: 150px;
              height: 40px;
              background: #3672fd;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: bold;
              font-size: 17px;
              color: #ffffff;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
</style>