<template>
  <div class="container">
    <div class="center-content">
      <!-- <CommunityBanner></CommunityBanner> -->
      <!-- enTitle="Popular content" -->
      <el-carousel height="40px" direction="vertical" :autoplay="true">
        <el-carousel-item v-for="(item,idx) in items" :key="idx">
          <div class="carousel-text">
            <img src="@/assets/images/laba.png">{{item.text}}
          </div>
        </el-carousel-item>
      </el-carousel>
      <!-- <ContentCom title="创意市场热门内容" enTitle="" :contentList="communityList"></ContentCom> -->
      <CommunityCom  :communityList="communityList"></CommunityCom>
      <div class="mt-60 mb-40">
        <!-- enTitle="creative market" -->
        <ContentCom title="创意市场" enTitle="" :contentList="rankList"></ContentCom>
      </div>
      <BottomCom></BottomCom>
    </div>
  </div>
</template>
<script>
  import CommunityBanner from "@/components/banner/communityBanner.vue";
  import BottomCom from "@/components/bottom/index.vue";
  import ContentCom from "@/components/content/index.vue";
  import CommunityCom from "@/components/community/index.vue";
  import { getPostList } from "@/api/main";
  export default {
    components: {
      BottomCom,
      ContentCom,
      CommunityBanner,
      CommunityCom
    },
    data() {
      return {
        items: [
          { id: 1, text: 'XXXX通过发表的内容获得了10元的收益' },
          { id: 2, text: 'XXXX通过发表的内容获得了20元的收益' },
          { id: 3, text: 'XXXX通过发表的内容获得了30元的收益' },
          { id: 4, text: 'XXXX通过发表的内容获得了40元的收益' },
        ],
        communityList: [],
        rankList: [],
        params: {
          sort: "desc",
          offset: 0,
          limit: 20,
          sort_by: "like"
        },
        rankParams: {
          sort: "desc",
          offset: 0,
          limit: 20,
          sort_by: "created_at"
        }
      };
    },
    created() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.getPostListReq();
      this.getPostListReqRank();
      this.$bus.$off("initData");
      this.$bus.$on("initData", item => {
        this.getPostListReq();
        this.getPostListReqRank();
      })
    },
    watch: {
      $route() {
        this.initArchor();
      },
    },
    mounted() {
      this.initArchor();
    },
    methods: {
      initArchor() {
        let anchorTarget = this.$route.query.anchorTarget || "";
        if (anchorTarget) {
          this.$nextTick(() => {
            document.querySelector("#" + anchorTarget).scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          });
        } else {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      },
      getPostListReq() {
        getPostList(this.params)
          .then((res) => {
            if (res.error == 0 && res.data && res.data.list) {
              this.communityList = res.data.list || [];

            } else {
              this.communityList = [];
            }
          })
          .catch((error) => {
            this.communityList = [];
          });
      },
      getPostListReqRank() {
        getPostList(this.rankParams)
          .then((res) => {
            if (res.error == 0 && res.data && res.data.list) {
              this.rankList = res.data.list || [];

            } else {
              this.rankList = [];
            }
          })
          .catch((error) => {
            this.rankList = [];
          });
      },
    },
  };
</script>
<style lang="less" scoped>
  .container {
    //   height: 100%;
    display: flex;
    /* 禁止文字选择 */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #f6f5f8;

    .center-content {
      padding-top: 128px;
      width: 100%;
      .el-carousel {
        width: 1200px;
        margin: 0 auto;
        margin-top: 20px;
        .carousel-text {
          display: flex;
          align-items: center;
          img {
            width: 36px;
            height: 36px;
            margin-right: 20px;
          }
        }
      }
    }
  }
</style>