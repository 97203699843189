<template>
    <div class="banner-box">
        <div class="banner-content">
            <div class="banner-title">行业解决方案</div>
            <!-- <div class="banner-desc">solution</div> -->
            <div class="banner-desc">用AI、数据、科技服务落地传统产业</div>
            <div class="banner-btn">
                <div class="btn cursor" @click="goAbout">其他行业前去定制</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        goAbout() {
            this.$router.push({
                path: "about?anchorTarget=contact"
            })
        }
    }
}
</script>
<style lang="less" scoped>
.banner-box {
    width: 100%;
    height: 555px;
    background: url("~@/assets/images/service/banner.png") center center no-repeat;
    background-size: 100% 100%;
    .banner-content {
        width: 1200px;
        display: flex;
        margin: 0 auto;
        height: 100%;
        flex-direction: column;
        .banner-title {
            font-weight: bold;
            font-size: 60px;
            color: #FFFFFF;
            line-height: 84px;
            text-align: center;
            margin-top: 138px;
        }
        .banner-desc {
            font-weight: bold;
            font-size: 24px;
            color: #FFFFFF;
            line-height: 32px;
            text-align: center;
            text-transform: uppercase;
            margin-top: 6px;
        }
        .banner-btn {
            margin-top: 73px;
            display: flex;
            justify-content: center;
            .btn {
                width: 263px;
                height: 68px;
                border-radius: 10px;
                border: 2px solid #FFFFFF;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                font-size: 28px;
                color: #FFFFFF;
            }
        }
    }
}
</style>