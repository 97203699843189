<template>
    <div class="product-box">
        <div class="product-center">
            <!-- <SubTitle :title="title" :enTitle="enTitle"></SubTitle> -->
            <SubDesc title="玩具行业" titleDesc="玩具厂商面临创造力匮乏、信息流通不畅、决策依据不足的挑战，我们专为此打造了BI产品与亲缘智伴（KIN AI）"></SubDesc>
            <div class="product-list">
                <div class="product-item" v-for="(item,idx) in productList" :key="idx" :id="item.elementId">
                    <div class="product-img">
                        <img :src="item.imgSrc">
                    </div>
                    <div class="product-info">
                        <SecondTitle :secTitle="item.name"></SecondTitle>
                        <div class="desc" v-html="item.desc"></div>
                        <div class="opt">
                            <div class="btn">立即体验</div>
                        </div>
                    </div>
                </div>
            </div>
            <SubDesc title="黄金行业" titleDesc="传统黄金行业线上线下融合不足、技术创新应用不足。我们专为此打造了数字化商城和AI私人定制助手"></SubDesc>
            <div class="product-list">
                <div class="product-item" v-for="(item,idx) in goldList" :key="idx">
                    <div class="product-img">
                        <img :src="item.imgSrc">
                    </div>
                    <div class="product-info">
                        <SecondTitle :secTitle="item.name"></SecondTitle>
                        <div class="desc" v-html="item.desc"></div>
                        <!-- <div class="opt">
                            <div class="btn">立即体验</div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="top-header">
                <SubTitle title="未来我们将开展更多的行业"></SubTitle>
                <div class="btn cursor" @click="goAbout">其他行业前去定制</div>
            </div>
        </div>

    </div>
</template>
<script>
    import SubTitle from "../subTitle/index.vue";
    import SecondTitle from "@/components/secondTitle/index.vue";
    import SubDesc from "@/components/subTitle/subDesc.vue";
    export default {
        components: {
            SubTitle,
            SecondTitle,
            SubDesc
        },
        data() {
            return {
                title: "BI产品",
                enTitle: "BI products",
                productList: [{
                    name: "BI产品",
                    desc: "旨在帮助企业决策者快速、科学地做出决策。我们利用大数据和先进算法。服务包括：<br>大数据支持：涵盖销售、IP和用户需求数据，提供全面市场分析。<br>科学算法：精准处理、分析和预测数据，帮助企业制定科学决策。"
                        + "<br>经验辅助：结合决策者经验，提供高效决策辅助，特别是在IP选择和营销方面。<br>全方位IP决策建议：整合IP影响力、属性和特征数据，提供全面IP决策支持。<br>产品策略支持：辅助制定产品投产、价格和IP策略，帮助企业在红海竞争中找到蓝海机会。",
                    imgSrc: require("@/assets/images/service/bi_1.png"),
                    elementId: "report"
                }, {
                    name: "亲缘智伴（KIN AI）",
                    desc: "专为玩具企业打造，通过先进的AI技术和全面的育儿科学，帮助企业快速构建专属的AI工具，并实现私域流量的高效转化。<br>该产品提供智能化的育儿指导和互动体验。通过精准的数据分析和用户画像，打造高效的种草内容，精准触达目标用户。融合了神奇创意和科学育儿理念，帮助企业推出创新的产品和服务。通过科学的育儿建议和创意互动内容，增强用户体验，提升品牌价值。通过AI技术和精准营销策略，快速打造和转化私域流量，实现从用户获取、互动到购买的全流程闭环管理，使得私域流量高效转化。",
                    imgSrc: require("@/assets/images/service/bi_2.png"),
                    elementId: "strategy"
                }],
                goldList: [{
                    name: "数字化商城",
                    desc: "我们为黄金行业精心打造了全面的数字化解决方案，旨在创新构建一个黄金商场平台，该平台致力于彻底打破黄金投资与工业用途之间的传统界限，实现两者的高效融合与互补。<br>积极与非遗工艺师展开合作，借助传统古法工艺来弘扬中国传统文化，大国工匠",
                    imgSrc: require("@/assets/images/service/bi_1.png"),
                }, {
                    name: "AI私人定制助手",
                    desc: "探索全新黄金定制时代，我们正引领一场前所未有的变革！借助先进的AI技术，我们为您精心打造独一无二的私人定制黄金体验。无论是追求精美的饰品，还是寻求个性化的投资佳品，我们都能满足您的独特品味与需求。让AI设计成为潮流的引领者，为您的黄金选择开启无限可能，让每一款黄金作品都闪耀着独特的光芒，彰显您的非凡品味与独特个性！",
                    imgSrc: require("@/assets/images/service/bi_1.png"),
                }]
            }
        },
        methods: {
            goAbout() {
                this.$router.push({
                    path: "About"
                })
            },
        }
    }
</script>
<style lang="less" scoped>
    .product-box {
        width: 100%;
        margin-top: 60px;

        .product-center {
            width: 1200px;
            display: flex;
            margin: 0 auto;
            flex-direction: column;

            .top-header {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 50px 0;

                .btn {
                    width: 198px;
                    height: 56px;
                    background: #3672FD;
                    border-radius: 10px;
                    font-weight: bold;
                    font-size: 20px;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .product-list {
                display: flex;
                flex-direction: column;
                margin-top: 50px;

                .product-item {
                    display: flex;
                    margin-bottom: 80px;
                    cursor: pointer;

                    .product-img {
                        img {
                            width: 600px;
                            height: 340px;
                            border-radius: 10px;
                        }
                    }

                    .product-info {
                        display: flex;
                        flex-direction: column;
                        padding-left: 30px;

                        .desc {
                            font-weight: 400;
                            font-size: 14px;
                            color: #626A83;
                            line-height: 20px;
                            text-align: justify;
                            font-style: normal;
                            margin-top: 24px;
                            padding-left: 16px;
                            flex: 1;
                        }

                        .opt {
                            display: flex;

                            /* justify-content: flex-end; */
                            .btn {
                                width: 150px;
                                height: 40px;
                                background: #3672FD;
                                border-radius: 10px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-weight: bold;
                                font-size: 17px;
                                color: #FFFFFF;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>