<template>
  <div class="container">
    <div class="center-content">
      <AboutBanner id="about"></AboutBanner>
      <BriefCom></BriefCom>
      <VisionCom></VisionCom>
      <PartnerCom></PartnerCom>
      <JoinCom id="join"></JoinCom>
      <ContactCom id="contact"></ContactCom>
      <BottomCom></BottomCom>
    </div>
  </div>
</template>
<script>
import AboutBanner from "@/components/banner/aboutBanner.vue";
import BottomCom from "@/components/bottom/index.vue";
import BriefCom from "@/components/about/brief.vue";
import VisionCom from "@/components/about/vision.vue";
import PartnerCom from "@/components/about/partner.vue";
import JoinCom from "@/components/about/join1.vue";
import ContactCom from "@/components/about/contact.vue";

export default {
  components: {
    BottomCom,
    AboutBanner,
    BriefCom,
    VisionCom,
    PartnerCom,
    JoinCom,
    ContactCom,
  },
  data() {
    return {};
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
  watch: {
    $route() {
      this.initArchor();
    },
  },
  mounted() {
    this.initArchor();
  },
  methods: {
    initArchor() {
      let anchorTarget = this.$route.query.anchorTarget || "";
      if (anchorTarget) {
        this.$nextTick(() => {
          document.querySelector("#" + anchorTarget).scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        });
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  //   height: 100%;
  display: flex;
  /* 禁止文字选择 */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #f6f5f8;
  .center-content {
    padding-top: 128px;
    width: 100%;
  }
}
</style>